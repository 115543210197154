<template lang="pug">
  .shared-sold-out.container-fluid
    AppOverlayLoader.loader(:state="inventoriesLoading")
    Filters(
      :filters="filters"
      :references="references"
      :references-loading="referencesLoading"
      :car-classes="carClasses"
      :car-classes-loading="carClassesLoading"
      @change:references="handleChangeReferences"
      @closed:references="fetchCarClasses()"
      @change:car-classes="handleChangeCarClasses"
      @closed:car-classes="handleCloseCarClassesDropdown"
    )
    SoldOutTable(
      :inventories="inventories"
      :sorting-data="sorting"
      @sorting="handleSorting"
    )
</template>

<script>
  // store modules
  import soldOutModule from "@/config/store/sold_out"
  import inventoryGroupsModule from "@/config/store/matching/inventory_group"
  import sharedCarClassMatchingModule from "@/config/store/shared_inventory_management/car_classes"
  import carClassMatchingModule from "@/config/store/matching/car_class"
  import shopsModule from "@/config/store/matching/shop"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // misc
  import { isEmpty } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  // components
  import SoldOutTable from "./Table"

  const soldOutMixin = withStoreModule(soldOutModule, {
    name: "soldOut",
    readers: {
      inventories: "items",
      inventoriesLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sorting: "sorting"
    },
    mutations: {
      setInventories: "SET_ITEMS",
      setFilters: "SET_FILTERS",
      setSoldOutSorting: "SET_SORTING"
    },
    actions: {
      fetchInventories: "FETCH_ITEMS",
      saveChanges: "UPDATE_ITEMS"
    }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroupsManagement",
    readers: { inventoryGroups: "items", inventoryGroupsLoading: "loading" },
    actions: { fetchInventoryGroups: "FETCH_ITEMS" },
    mutations: { setSorting: "SET_SORTING" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shopMatching",
    readers: { shops: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const sharedCarClassMatchingMixin = withStoreModule(sharedCarClassMatchingModule, {
    name: "sharedCarClassMatching",
    readers: { sharedCarClassesLoading: "loading", sharedCarClassItems: "items" },
    actions: { fetchSharedCarClassesAction: "FETCH_ITEMS" }
  })

  const carClassMatchingMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatching",
    readers: { shopCarClassesLoading: "loading", carClassItems: "items" },
    actions: { fetchCarClassesAction: "FETCH_ITEMS" }
  })

  export default {
    components: {
      SoldOutTable,
      Filters: () => import("./Filters"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [
      soldOutMixin,
      inventoryGroupsMixin,
      carClassMatchingMixin,
      sharedCarClassMatchingMixin,
      withToyotaOrganizationsMountedOrder,
      shopsMixin
    ],

    async mounted() {
      this.setInventories([])
      this.setSoldOutSorting({ field: this.sortingField, direction: "asc" })

      if (isSharedInventoryEnabled()) {
        this.fetchInventoryGroups({ pagination: { _disabled: true }, matched_only: true, with_shops_only: true })
      } else {
        this.fetchShops({ pagination: { _disabled: true }, matched_only: true, synced_only: true })
      }

      if (!isEmpty(this.filters.references)) await this.fetchCarClassesAndUpdateFilters()
      if (this.isValidFilters) this.fetchInventories()
    },

    computed: {
      sortingField() {
        return isSharedInventoryEnabled() ? "inventory_groups.name" : "shops.name"
      },

      referencesLoading({ inventoryGroupsLoading, shopsLoading }) {
        return isSharedInventoryEnabled() ? inventoryGroupsLoading : shopsLoading
      },

      carClassesLoading({ sharedCarClassesLoading, shopCarClassesLoading }) {
        return isSharedInventoryEnabled() ? sharedCarClassesLoading : shopCarClassesLoading
      },

      references({ inventoryGroups, shops }) {
        return isSharedInventoryEnabled() ? inventoryGroups : shops
      },

      isValidFilters({ filters }) {
        return !isEmpty(filters.references) && !isEmpty(filters.carClasses)
      },

      referenceIds({ filters }) {
        return filters.references.map(e => e.id)
      },

      carClasses({ sharedCarClassItems, carClassItems }) {
        return isSharedInventoryEnabled() ? sharedCarClassItems : carClassItems
      }
    },

    methods: {
      async fetchCarClassesAndUpdateFilters() {
        await this.fetchCarClasses()
        const activeCarClasses = this.carClasses.map(({ id }) => id)

        if (!isEmpty(this.filters.carClasses)) {
          const selectedCarClasses = this.filters.carClasses.filter(({ id }) => activeCarClasses.includes(id))

          this.setFilters({ carClasses: selectedCarClasses })
        }
      },

      fetchCarClasses() {
        if (isSharedInventoryEnabled()) {
          return this.fetchSharedCarClassesAction({ inventory_group_ids: this.referenceIds })
        } else {
          return this.fetchCarClassesAction({
            shop_ids: this.referenceIds,
            pagination: { _disabled: true },
            synced_only: true
          })
        }
      },

      handleChangeReferences(references) {
        this.setFilters({ references, carClasses: [] })
        this.setInventories([])
      },

      handleChangeCarClasses(carClasses) {
        this.setFilters({ carClasses })
      },

      handleCloseCarClassesDropdown() {
        if (this.isValidFilters) {
          this.fetchInventories()
        } else {
          this.setInventories([])
        }
      },

      handleSorting(sorting) {
        this.setSoldOutSorting(sorting)

        this.fetchInventories()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/shared-inventory-management.sass"

  .shared-sold-out
    position: relative

    .sticky
      position: sticky
      top: 0
      z-index: 10
      background: $default-white
      max-width: 100%
      padding-top: 10px
      width: fit-content

    .loader
      z-index: 10000002
</style>
