import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { add as dateAdd } from "date-fns"
import { currentDateInTimeZone } from "@/helpers/common"

import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/inventory"
const today = currentDateInTimeZone()

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: [],
  withFilters: {
    shop: {},
    car_classes: [],
    start_date: today,
    end_date: dateAdd(today, { months: 2 }),
    monthPickerEnabled: true
  },
  withPagination: true
})

store.mergeState({
  items: {},
  otaData: {},
  processingShopIds: [],
  carClassesForPage: [],
  hiddenSaleStopDropdowns: new Set(),
  togglers: {}
})

store.mergeMutations(extractMutations())

const {
  FETCH_ITEMS,
  UPDATE_ITEMS,
  UPDATE_PAGINATION_DATA,
  SWITCH_HIDDEN_SALE_STOP_DROPDOWN,
  IMPORT_INVENTORY,
  IMPORT_INVENTORY_AVAILABLE,
  RESEND_INVENTORY
} = extractActions({ baseURI: BASE_URI })

store.mergeActions({ FETCH_ITEMS, UPDATE_ITEMS, IMPORT_INVENTORY, RESEND_INVENTORY }, withLoading)
store.mergeActions({ UPDATE_PAGINATION_DATA, SWITCH_HIDDEN_SALE_STOP_DROPDOWN, IMPORT_INVENTORY_AVAILABLE })

export default store
