import { api } from "@/config"
import { isEmpty, map, orderBy } from "lodash-es"
import { format } from "date-fns"
import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
import { paginateData } from "@/config/store/StoreItemsModule/helpers"

const paramsFromState = ({ filters, carClassesForPage }, customParams = {}) => {
  const { shop, start_date, end_date } = filters
  return {
    filters: {
      shop_id: shop.id,
      car_class_ids: map(carClassesForPage, "id"),
      start_date: format(start_date, DATE_FNS_DATE_FORMAT),
      end_date: format(end_date, DATE_FNS_DATE_FORMAT),
      ...customParams
    }
  }
}

const mapImportFilters = (shops, carClasses, inventoryGroups = []) => ({
  import_filters: {
    shop_ids: map(shops, "id"),
    car_class_ids: map(carClasses, "id"),
    inventory_group_ids: map(inventoryGroups, "id")
  }
})

const mapResendFilters = ({ carClasses, reference, chosenOtaIds, dates }) => ({
  resend_filters: {
    car_class_ids: map(carClasses, "id"),
    reference_id: reference.id,
    start_date: format(dates[0], DATE_FNS_DATE_FORMAT),
    end_date: format(dates[1], DATE_FNS_DATE_FORMAT),
    ota_ids: chosenOtaIds
  }
})

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, dispatch, state }) => {
    dispatch("UPDATE_PAGINATION_DATA")

    const params = paramsFromState(state)
    const response = await api.get(baseURI, { params })

    commit("SET_ITEMS", response.data.data)
  },

  UPDATE_ITEMS: async ({ commit, dispatch, state }, { changed_inventories, sale_stop_days }) => {
    if (isEmpty(changed_inventories) && isEmpty(sale_stop_days)) {
      return Promise.resolve
    }

    dispatch("UPDATE_PAGINATION_DATA")

    const params = { ...paramsFromState(state), changed_inventories, sale_stop_days }

    const response = await api.put(baseURI, params)

    commit("SET_ITEMS", response.data.data)
  },

  UPDATE_PAGINATION_DATA: ({ commit, state }) => {
    const { filters, pagination } = state

    const pagedData = paginateData({ data: filters.car_classes, ...pagination })

    commit("SET_CAR_CLASSES_FOR_PAGE", orderBy(pagedData.data, "order", "ASC"))
    commit("SET_PAGINATION_DATA", pagedData.pagination)
  },

  SWITCH_HIDDEN_SALE_STOP_DROPDOWN: ({ commit, state }, { carClassId }) => {
    const hiddenSaleStopDropdowns = new Set(state.hiddenSaleStopDropdowns)

    if (hiddenSaleStopDropdowns.has(carClassId)) {
      hiddenSaleStopDropdowns.delete(carClassId)
    } else {
      hiddenSaleStopDropdowns.add(carClassId)
    }

    commit("SET_HIDDEN_SALE_STOP_DROPDOWNS", hiddenSaleStopDropdowns)
  },

  IMPORT_INVENTORY: ({ state }, { shops, carClasses, inventoryGroups = [] }) => {
    const importFilters = mapImportFilters(shops, carClasses, inventoryGroups)

    const params = paramsFromState(state, importFilters)
    api.post(`${baseURI}/import_inventory`, params)
  },

  RESEND_INVENTORY: async (_, filters) => {
    const resendFilters = mapResendFilters(filters)

    await api.post(`${baseURI}/resend_inventory`, { filters: { ...resendFilters } })
  },

  IMPORT_INVENTORY_AVAILABLE: async ({ state }, { shops, carClasses }) => {
    const importFilters = mapImportFilters(shops, carClasses)

    const params = paramsFromState(state, importFilters)
    try {
      await api.post(`${baseURI}/import_inventory_available`, params)

      return true
    } catch (_) {
      return false
    }
  }
})
