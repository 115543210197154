import { setStatePayload } from "@/config/store/StoreItemsModule/helpers"
import { keys, reduce } from "lodash-es"

export default () => ({
  SET_CAR_CLASSES_FOR_PAGE: setStatePayload("carClassesForPage"),

  SET_TOGGLERS: (state, { uniqId, toggleState }) => {
    state.togglers = { ...state.togglers, [uniqId]: toggleState }
  },

  TOGGLE_ALL_TOGGLERS: (state, { value }) => {
    const togglers = reduce(
      keys(state.togglers),
      (newSet, k) => {
        newSet[k] = value
        return newSet
      },
      {}
    )
    state.togglers = togglers
  },

  UPDATE_PROCESSING_SHOP: (state, { shopId, processing }) => {
    if (processing) {
      if (!state.processingShopIds.includes(shopId)) {
        state.processingShopIds.push(shopId)
      }
    } else {
      const index = state.processingShopIds.indexOf(shopId)
      if (index > -1) {
        state.processingShopIds.splice(index, 1)
      }
    }
  }
})
