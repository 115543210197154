import { api } from "@/config"
import { mapFiltersFromState } from "./index"

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state }) => {
    const { sorting } = state

    const params = { filters: mapFiltersFromState(state), sorting }
    const response = await api.get(baseURI, { params })

    commit("SET_ITEMS", response.data.data)
  }
})
