import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import mapActions from "./actions"

const BASE_URI = "/sold_out"

export const mapFiltersFromState = ({ filters: { references, carClasses } }) => ({
  reference_ids: references.map(({ id }) => id),
  car_class_ids: carClasses.map(({ id }) => id)
})

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    references: [],
    carClasses: []
  },
  withSorting: true
})

const { FETCH_ITEMS } = mapActions({ baseURI: BASE_URI })

store.mergeActions({ FETCH_ITEMS }, withLoading)

export default store
