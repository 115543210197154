<template lang="pug">
  .sold-out-table-wrapper
    table.sold-out-table
      thead
        tr
          th.sticky-col.name.sortable(@click="handleSorting(`${referenceName}.name`)")
            .header-content
              span {{ referenceTitle }}
              FaIcon.icon(:icon="sortingIcon(`${referenceName}.name`)")
          th.sticky-col.name.sortable(@click="handleSorting('car_classes.name')")
            .header-content
              span {{ $t("sold_out.attributes.car_class") }}
              FaIcon.icon(:icon="sortingIcon('car_classes.name')")
          th.sticky-col.days
            .header-content
              span {{ $t("sold_out.attributes.sold_out_days") }}
              AppTooltip(
                :title="$t('sold_out.days_tooltip')"
                icon="question-circle"
              )
      tbody
        tr(v-if="isEmpty(inventories)")
          td(:colspan="3") {{ $t('no_data') }}
        template(v-else)
          Item(
            v-for="(inventory, index) in inventories"
            :item="inventory"
            :key="`${index}_${isSidebarHidden}`"
            @clicked="openCalendar(inventory, $event)"
          )
</template>

<script>
  import sharedInventoryModule from "@/config/store/shared_inventory_management"
  import inventoryManagementModule from "@/config/store/inventory_management"
  import withStoreModule from "@/mixins/withStoreModule"

  import { startOfMonth, endOfMonth } from "date-fns"
  import { bus } from "@/config"
  import { isEmpty } from "lodash-es"
  import { isSharedInventoryEnabled } from "@/helpers/organization"

  import withSorting from "@/mixins/withSorting"

  const sharedInventoryMixin = withStoreModule(sharedInventoryModule, {
    name: "sharedInventory",
    mutations: {
      setSharedFilters: "SET_FILTERS"
    }
  })

  const inventoryManagementMixin = withStoreModule(inventoryManagementModule, {
    name: "inventoryManagement",
    mutations: {
      setShopFilters: "SET_FILTERS"
    }
  })

  export default {
    mixins: [sharedInventoryMixin, withSorting, inventoryManagementMixin],

    mounted() {
      bus.on("sidebar-switched", value => {
        this.isSidebarHidden = value
      })
    },

    beforeDestroy() {
      bus.off("sidebar-switched")
    },

    components: {
      Item: () => import("./Item"),
      AppTooltip: () => import("@/components/elements/AppTooltip")
    },

    props: {
      inventories: {
        type: Array,
        required: true
      },
      sortingData: Object
    },

    data() {
      return {
        isSidebarHidden: false
      }
    },

    computed: {
      referenceName() {
        return isSharedInventoryEnabled() ? "inventory_groups" : "shops"
      },

      referenceTitle() {
        return isSharedInventoryEnabled()
          ? this.$t("sold_out.attributes.inventory_group")
          : this.$t("sold_out.attributes.shop")
      },

      paramsReferenceField() {
        return isSharedInventoryEnabled() ? "inventoryGroup" : "shop"
      },

      paramsCarClassesField() {
        return isSharedInventoryEnabled() ? "carClasses" : "car_classes"
      },

      routeName() {
        return isSharedInventoryEnabled() ? "SharedInventoryManagement" : "InventoryManagement"
      }
    },

    methods: {
      isEmpty,

      openCalendar(item, date) {
        const filters = {
          dates: [startOfMonth(new Date(date)), endOfMonth(new Date(date))],
          [this.paramsCarClassesField]: [{ id: item.car_class_id, name: item.car_class_name }],
          [this.paramsReferenceField]: { id: item.reference_id, name: item.reference_name }
        }

        if (isSharedInventoryEnabled()) {
          this.setSharedFilters(filters)
        } else {
          this.setShopFilters(filters)
        }

        this.$router.push({ name: this.routeName })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/matchings.sass"

  $border-style: 1px solid $default-purple-light-line

  .sold-out-table-wrapper
    +listing-container

    table
      +custom-table

      thead
        th
          padding: 0
          border-left: $border-style
          border-right: $border-style

          .header-content
            padding: 0 10px
            width: 100%
            border-top: $border-style
            border-bottom: $border-style

          &.sticky-col
            +sticky-col
            top: 0
            z-index: 10

          &.name
            width: 300px
      td
        border: $border-style !important
        border-top: none !important
</style>
